import * as React from "react";
import KoFiIcon from "-!svg-react-loader!./social-kofi.svg";
import BMACIcon from "-!svg-react-loader!./social-bmac.svg";
import "./SocialLinks.scss";

// markup
export default function TipLinks(props) {
  return (
    <ul
      className={
        "social-list social-list--tips " +
        (props.size === "large" ? "social-list--large " : " ") +
        (props.className ? props.className : "")
      }
    >
      <li className="social-list__item">
        <a
          href="https://www.ko-fi.com/yvetterussell"
          target="_blank"
          rel="noreferrer"
          className="social-list__link"
        >
          <KoFiIcon className="social-list__icon" />
        </a>
      </li>
      <li className="social-list__item">
        <a
          href="https://www.buymeacoffee.com/yvetterussell"
          target="_blank"
          rel="noreferrer"
          className="social-list__link"
        >
          <BMACIcon className="social-list__icon" />
        </a>
      </li>
    </ul>
  );
}
