import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header/Header";
import Hero from "../components/Heading/Hero";
import PageHeading from "../components/Heading/PageHeading";
import Container from "../components/Container/Container";
import BucketsRow from "../components/Buckets/BucketsRow";
import Bucket from "../components/Buckets/Bucket";
import Button from "../components/Button/Button";
import SocialLinks from "../components/Social/SocialLinks";
import TipLinks from "../components/Social/TipLinks";
import Footer from "../components/Footer/Footer";
import SubscribeCallout from "../components/SubscribeCallout/SubscribeCallout";
import "./contact.scss";

export default function About() {
  return (
    <main>
      <Helmet title="Yvette Russell | Contact" defer={false} />
      <Header />
      <Hero type="tight">
        <PageHeading>
          <h2>
            Contact <strong>Yvette Russell</strong>
          </h2>
        </PageHeading>
      </Hero>
      <Container name="contact-email" type="background-decor">
        <p className="contact-email">
          <span className="contact-email__heading">Email me at</span>

          <span className="contact-email__email">
            hello{" "}
            <span className="contact-email__highlight">(at)</span> yvetterussell.com
          </span>
        </p>
      </Container>
      <Container type="background">
        <BucketsRow className="u-pad-top-xl">
          <SubscribeCallout type="stripped" />
        </BucketsRow>
      </Container>
      <Container name="buckets" type="background" className="u-pad-mar-md">
        <BucketsRow className="u-pad-top-xl">
          <Bucket title="Social Media">
            <p>
              I’m not on social media much these days, but you can follow me if
              you like!
            </p>
            <SocialLinks size="large" className="centered u-mar-top-sm" />
          </Bucket>
          <Bucket title="Patreon">
            <p>
              For news and updates on my ongoing series, follow me on Patreon.
            </p>
            <Button
              url="https://www.patreon.com/yvetterussell"
              target="_new"
              className="u-mar-top-sm"
            >
              Follow Me on Patreon
            </Button>
          </Bucket>
          <Bucket title="Leave a Tip?">
            <p>
              Enjoy my books? Consider leaving a tip so I can keep posting work for free.
            </p>
            <TipLinks type="tip" size="large" className="centered u-mar-top-sm" />
          </Bucket>
        </BucketsRow>
      </Container>
      <Footer />
    </main>
  );
}
