import * as React from "react";
import "./Buckets.scss";

// markup
export default function BucketsRow(props) {
	return (
		<div
			className={
				"buckets" +
				(props.type ? "bucket--" + props.type + " " : " ") +
				props.className
			}
		>
			{props.children}
		</div>
	);
}
