import * as React from "react";
import "./Buckets.scss";

// markup
export default function Bucket(props) {
	return (
		<div
			className={
				"bucket " +
				(props.type ? "bucket--" + props.type + " " : " ") +
				(props.className ? props.className + " " : "")
			}
		>
			<h3 className="bucket__heading">{props.title}</h3>
			{props.children}
		</div>
	);
}
